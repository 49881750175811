import { Box, IconButton, Button } from '@mui/material';
import { DataGridPro, GridCellEditCommitParams, GridCellParams, GridColDef, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid-pro';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TimeAgo from 'timeago-react';
import { PatchModel } from '../../../../models/path.model';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { SearchModel } from '../../../../models/search.model';
import { SearchInput } from '../../../shared/ui/SearchInput';
import { Account } from '../../../../models/account.model';
import { createAccount, getAccounts, patchAccount } from '../../../../services/account.service';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import { ConfirmModal } from '../../../shared/ui/modals/ConfirmModal';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { StatusEdit } from '../../../shared/ui/grid/StatusEdit';
import { RenderStatus } from '../../../shared/ui/grid/RenderStatus';
import { useSnackBar } from '../../../../contexts/SnackbarContext';
import { AuthorAvatar } from '../../../shared/ui/AuthorAvatar';

export function Accounts() {
   const getSortSettingsFromLocalStorage = () => {
      const sortSettingsStr = localStorage.getItem('adminAccountsSort');
      if (sortSettingsStr) {
         const sortSettings = JSON.parse(sortSettingsStr);
         return { orderBy: sortSettings.orderBy, asc: sortSettings.asc };
      } else {
         return { orderBy: 'name', asc: true };
      }
   };

   const [searchObj, setSearchObj] = useState<SearchModel>({
      page: 0,
      rowsPerPage: 100,
      search: '',
      ...getSortSettingsFromLocalStorage(),
   });
   const [loading, setLoading] = useState<boolean>(false);
   const [rows, setRows] = useState<Account[]>([]);
   const [total, setTotal] = useState(0);
   const [selectedObj, setSelectedObj] = useState<any>(null);
   const [editCellObj, setEditCellObj] = useState<string>();
   const navigate = useNavigate();
   const snackbar = useSnackBar();

   const [gridHeight, setGridHeight] = useState<number>(0);

   useEffect(() => {
      function updateGridHeight() {
         const topHeight = document.getElementById('admin-top')?.clientHeight;
         setGridHeight(window.innerHeight - (topHeight ?? 0));
      }

      updateGridHeight();

      window.addEventListener('resize', updateGridHeight);

      return () => {
         window.removeEventListener('resize', updateGridHeight);
      };
   }, []);

   useEffect(() => {
      const timeOutId = setTimeout(() => {
         setSearchObj({ ...searchObj, page: 0 });
         retrieveAccounts();
      }, 500);
      return () => clearTimeout(timeOutId);
   }, [searchObj.search]);

   useEffect(() => {
      setSearchObj({ ...searchObj, page: 0 });
      retrieveAccounts();
   }, [searchObj.rowsPerPage, searchObj.orderBy, searchObj.asc]);

   useEffect(() => {
      if (searchObj.orderBy && searchObj.asc !== undefined) updateSortSettings(searchObj.orderBy, searchObj.asc ?? true);
   }, [searchObj.orderBy, searchObj.asc]);

   useEffect(() => {
      retrieveAccounts();
   }, [searchObj.page]);

   useEffect(() => {
      retrieveAccounts();
   }, []);

   const updateSortSettings = (orderBy: any, asc: boolean) => {
      const sortSettings = { orderBy, asc };
      localStorage.setItem('adminAccountsSort', JSON.stringify(sortSettings));
   };

   const columns: GridColDef[] = [
      {
         field: 'name',
         headerName: 'Name',
         editable: true,
         filterable: false,
         flex: 1,
      },
      {
         field: 'isActive',
         headerName: 'Status',
         editable: true,
         filterable: false,
         renderEditCell: (params: GridRenderCellParams<any, any, any>) => <StatusEdit {...params} />,
         renderCell: (params: any) => <RenderStatus {...params} />,
      },
      {
         field: 'createdAt',
         headerName: 'Creation',
         editable: false,
         filterable: false,
         flex: 1,
         renderCell: (params: GridRenderCellParams<any, any, any>) => <TimeAgo datetime={new Date(params.row.createdAt.toString())} />,
      },
      {
         field: 'author',
         headerName: 'Created by',
         editable: false,
         filterable: true,
         sortable: false,
         align: 'center',
         renderCell: (params: GridRenderCellParams<any, any, any>) => <AuthorAvatar firstname={params.row.createdBy?.firstname} lastname={params.row.createdBy?.lastname} />,
      },
      {
         field: 'edit',
         headerName: '',
         editable: false,
         sortable: false,
         filterable: false,
         disableColumnMenu: true,
         renderCell: (params: GridRenderCellParams<any, any, any>) => {
            return (
               <div className="flex gap-small">
                  <IconButton aria-label="edit" component="label" onClick={() => navigate(`/admin/accounts/${params.row.id}`)}>
                     <EditIcon />
                  </IconButton>

                  <IconButton aria-label="delete" onClick={() => setSelectedObj(params.row.id)}>
                     <DeleteIcon />
                  </IconButton>
                  <ConfirmModal
                     selectedObj={selectedObj === params.row.id ? selectedObj : null}
                     setSelectedObj={setSelectedObj}
                     icon={<HighlightOffIcon color="error" fontSize="large" />}
                     title="Confirm Delete"
                     message="Are you sure you want to delete the selected account?"
                     buttonText="Delete"
                     onConfirm={() => {
                        updateAccount({
                           id: selectedObj,
                           field: 'isDeleted',
                           value: true,
                        } as PatchModel);
                        setSearchObj({ ...searchObj, page: 0 });
                        setRows(rows.filter((row: Account) => row.id !== selectedObj));
                        setTotal(total - 1);
                        setSelectedObj(null);
                     }}
                  />
               </div>
            );
         },
      },
   ];

   async function newAccount() {
      try {
         const responseAccount = await createAccount();
         if (!responseAccount.error) {
            snackbar.success('Account successfully created.');
            navigate(`/admin/accounts/${responseAccount.accountId}`);
         } else {
            snackbar.success("Account couldn't be created.");
         }
      } catch (e) {
         console.log(e);
      }
   }

   async function updateAccount(params: GridCellEditCommitParams, isDeleting?: boolean) {
      if (JSON.stringify(params.value) === editCellObj) return;

      try {
         const response = await patchAccount(params as PatchModel);
         if (!response.error) {
            snackbar.success(`Account successfully ${isDeleting ? 'deleted' : 'updated'}.`);
         } else {
            snackbar.error(`Error while ${isDeleting ? 'deleting' : 'updating'} the account.`);
         }
      } catch (e) {
         console.log(e);
      }
   }

   async function retrieveAccounts() {
      try {
         setLoading(true);
         const responseAccounts = await getAccounts(
            {
               ...searchObj,
               orderBy: searchObj.orderBy ?? 'name',
               asc: searchObj.asc ?? true,
            },
            true
         );
         if (!responseAccounts.error) {
            setRows(responseAccounts.accounts);
            setTotal(responseAccounts.total);
            setLoading(false);
         } else {
            setLoading(false);
         }
      } catch (e) {
         setLoading(false);
      }
   }

   return (
      <div>
         <div id="admin-top" className="flex between items-center admin-top">
            <Button color="primary" size="medium" variant="outlined" startIcon={<PersonAddAltIcon />} onClick={newAccount}>
               Account
            </Button>
            <SearchInput placeholder="Accounts" value={searchObj.search ?? ''} setValue={(value: string) => setSearchObj({ ...searchObj, search: value })} />
         </div>
         <DataGridPro
            sx={{ minHeight: gridHeight, height: gridHeight, maxHeight: gridHeight, backgroundColor: 'white' }}
            rows={rows}
            columns={columns}
            rowCount={total}
            loading={loading}
            pageSize={searchObj.rowsPerPage}
            onPageSizeChange={(newPageSize: number) => {
               setSearchObj({
                  ...searchObj,
                  page: 0,
                  rowsPerPage: newPageSize,
               });
            }}
            disableSelectionOnClick={true}
            onPageChange={(page: number) => setSearchObj({ ...searchObj, page: page })}
            rowsPerPageOptions={[5, 10, 20, 100]}
            pagination
            paginationMode="server"
            sortingMode="server"
            sortModel={[
               {
                  field: searchObj.orderBy ?? '',
                  sort: searchObj.asc === true ? 'asc' : 'desc',
               },
            ]}
            onSortModelChange={(sortModel: GridSortModel) => {
               if (sortModel[0] === undefined) {
                  setSearchObj({ ...searchObj, asc: true });
               } else {
                  setSearchObj({
                     ...searchObj,
                     orderBy: sortModel[0]?.field ?? 'sort',
                     asc: sortModel[0]?.sort == 'asc' ? true : false,
                  });
               }
            }}
            onCellEditStart={(params: GridCellParams) => setEditCellObj(JSON.stringify(params.value))}
            onCellEditCommit={(params: GridCellEditCommitParams) => updateAccount(params)}
         />
      </div>
   );
}
